import axios from 'axios';

const restClient = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_SERVER_DEV_URL : process.env.REACT_APP_API_SERVER_PROD_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  }
});

// request interceptor
restClient.interceptors.request.use(
  (config) => {
    return config;
  }
);

// response interceptor
restClient.interceptors.response.use(
  (response) => {
    return response;
  }
);

export default restClient;