import { create } from 'zustand';

const useStore = create((set) => ({
  user: null,
  environment: 'production',
  isImpersonating: false,
  impersonatedEmail: null,
  setUser: (user) => set({ user: user }),
  setEnvironment: (env) => set({ environment: env }),
  removeUser: () => set({ user: null }),
  setImpersonating: (isImpersonating) =>
    set({ isImpersonating: isImpersonating }),
  setImpersonatedEmail: (email) => set({ impersonatedEmail: email }),
}));

export default useStore;
