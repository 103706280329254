import { faBuildingColumns, faBug, faListCheck, faFileCircleXmark, faSliders, faTruck, faFileLines, faTabletScreenButton, faChartSimple } from '@fortawesome/free-solid-svg-icons';

export const TMAPPS_CONFIG = {
  APPS: [
    {
      name: 'PE - Defect Control System',
      url: '/dcs',
      icon: faBug,
      permissionGroups: ['/TMAPPS/dcs_user'],
    },
    {
      name: 'RM - NCR Application',
      url: '/rmncr',
      icon: faFileCircleXmark,
      permissionGroups: ['/TMAPPS/mfnc_user'],
    },
    {
      name: 'Bank Guarantee',
      url: '/bg',
      icon: faBuildingColumns,
      permissionGroups: ['/TMAPPS/bg_user'],
    },
    {
      name: 'EBS Responsibilities',
      url: '/ebs-resp',
      icon: faListCheck,
      permissionGroups: ['/TMAPPS/ebs_resp_user'],
    },
    {
      name: 'MF - Order Status',
      url: '/mf-order-status',
      icon: faTruck,
      permissionGroups: ['/TMAPPS/mf_order_status_user'],
    },
    {
      name: 'MF - PLM Report',
      url: '/plm-report',
      icon: faFileLines,
      permissionGroups: ['/TMAPPS/plm_report_user'],
    },
    {
      name: 'PE - Inspection App Console',
      url: '/pe-inspection-console',
      icon: faTabletScreenButton,
      permissionGroups: ['/TMAPPS/inspection_app_user'],
    },
    {
      name: 'Instrument Calibration',
      url: '/inst-calib',
      icon: faSliders,
      permissionGroups: ['/TMAPPS/instrument_calib_user'],
    },
    {
      name: 'PE - Inventory Report',
      url: '/pe-invent-report',
      icon: faChartSimple,
      permissionGroups: ['/TMAPPS/pe_inventory_report_user'],
    },
  ],
  ADMIN_GROUP: '/TMAPPS/admin',
};
