import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import './index.css';
import App from './App';

const oidcConfig = {
  authority:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_TMIDENTITY_DEV_DOMAIN
      : process.env.REACT_APP_TMIDENTITY_PROD_DOMAIN,
  client_id: 'tmapps',
  redirect_uri:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DCS_DEV_DOMAIN
      : process.env.REACT_APP_DCS_PROD_DOMAIN,
  response_type: 'code',
  loadUserInfo: true,
  onSigninCallback: () => {
    window.location.replace(oidcConfig.redirect_uri);
  },
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider {...oidcConfig}>
    <BrowserRouter>
      <React.StrictMode>
        <ChakraProvider>
          <App />
        </ChakraProvider>
      </React.StrictMode>
    </BrowserRouter>
  </AuthProvider>
);
