import { useState } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  Text,
  IconButton,
  MenuOptionGroup,
  Divider,
  MenuGroup,
  VStack,
  HStack,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useAuth } from 'react-oidc-context';

import ImpersonateDialog from '../ImpersonateDialog';
import useStore from '../../lib/store';

export default function Header({ user }) {
  const auth = useAuth();
  const environment = useStore((state) => state.environment);
  const impersonatedEmail = useStore((state) => state.impersonatedEmail);
  const isImpersonating = useStore((state) => state.isImpersonating);
  const setEnvironment = useStore((state) => state.setEnvironment);
  const setImpersonatedEmail = useStore((state) => state.setImpersonatedEmail);
  const setImpersonating = useStore((state) => state.setImpersonating);
  const setUser = useStore((state) => state.setUser);

  const [isImpersonateOpen, setIsImpersonateOpen] = useState(false);

  const handleOpenImpersonateDialog = () => {
    setIsImpersonateOpen(true);
  };

  const handleCloseImpersonateDialog = () => {
    setIsImpersonateOpen(false);
  };

  const handleImpersonate = (email) => {
    console.log('storeImpersonateUser', email);
    setImpersonatedEmail(email);
    setImpersonating(true);
    const userProfile = user.profile;
    userProfile.email = email;
    setUser({ ...user, profile: userProfile });
    console.log('impersonatedEmail', impersonatedEmail);
  };

  const handleLogout = () => {
    auth.signoutRedirect();
  };

  const isAdmin = () => {
    return user
      ? user.profile['user-groups']?.includes('/TMAPPS/admin')
      : false;
  };

  const handleEnvChange = (env) => {
    console.log('env changed to', env);
    setEnvironment(env);
  };

  return (
    <>
      <Box bg='gray.100' px={4} py={3} position={'absolute'} width={'100%'}>
        <Flex alignItems='center' justifyContent='space-between'>
          <Box>
            <ChakraLink as={ReactRouterLink} to={'/'}>
              <img
                width={100}
                height={30}
                src='https://www.tmeic.com/themes/custom/tmeic/logo.svg'
                alt='TMEIC logo'
              />
            </ChakraLink>
          </Box>
          <Box>
            <Flex alignItems='center'>
              {isImpersonating && (
                <VStack alignItems='flex-start' spacing={0} mr={4}>
                  <Text fontSize='xs' color='red.500'>
                    Impersonating as: {impersonatedEmail}
                  </Text>
                  <Text fontSize='xs' color='red.500' fontWeight={'medium'}>
                    Environment: {environment}
                  </Text>
                </VStack>
              )}
              <HStack spacing={0}>
                <Avatar size='sm' name={user && user.profile?.name} mr={4} />
                <VStack alignItems='flex-start' spacing={0}>
                  <Text mr={4}>{user && user.profile?.name}</Text>
                  <Text fontSize='2xs' color='gray.500'>
                    {user && user.profile?.email}
                  </Text>
                </VStack>
              </HStack>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Menu'
                  icon={<HamburgerIcon />}
                  variant='outline'
                />
                <MenuList>
                  {isAdmin() && (
                    <>
                      <MenuOptionGroup
                        defaultValue={environment}
                        title='Environment'
                        type='radio'
                        onChange={(value) => handleEnvChange(value)}
                      >
                        <MenuItemOption value='production'>
                          Production
                        </MenuItemOption>
                        <MenuItemOption value='staging'>Staging</MenuItemOption>
                      </MenuOptionGroup>
                      <Divider />
                      <MenuGroup title='Actions'>
                        <MenuItemOption
                          onClick={() => handleOpenImpersonateDialog()}
                        >
                          Impersonate User
                        </MenuItemOption>
                      </MenuGroup>
                      <Divider />
                    </>
                  )}
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <ImpersonateDialog
        isOpen={isImpersonateOpen}
        onClose={handleCloseImpersonateDialog}
        handleImpersonate={handleImpersonate}
      />
    </>
  );
}
