import { useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, AlertIcon, Spinner, Text } from '@chakra-ui/react';
import { TMAPPS_CONFIG } from '../config';

const Home = () => {
  const auth = useAuth();
  const [userinfo, setUserinfo] = useState(null);
  const [allApps, setAllApps] = useState(TMAPPS_CONFIG.APPS);

  useLayoutEffect(() => {
    setUserinfo(auth.user);
  }, [auth]);

  useLayoutEffect(() => {
    if (userinfo) {
      const isAdmin = userinfo.profile['user-groups']?.includes(
        TMAPPS_CONFIG.ADMIN_GROUP
      );
      if (isAdmin) {
        setAllApps(TMAPPS_CONFIG.APPS);
      } else {
        const filteredApps = TMAPPS_CONFIG.APPS.filter((app) => {
          return userinfo.profile['user-groups']?.some((group) =>
            app.permissionGroups.includes(group)
          );
        });
        setAllApps(filteredApps);
      }
    }
  }, [userinfo]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {userinfo && allApps.length > 0 ? (
          allApps.map((app) => (
            <Link to={app.url} key={app.name}>
              <div
                key={app.name}
                style={{
                  margin: '10px',
                  marginTop: '80px',
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
                  width: '200px',
                  color: '#666',
                  fontWeight: 'normal',
                  fontSize: '14px',
                  minHeight: '75px',
                }}
              >
                <FontAwesomeIcon
                  icon={app.icon}
                  style={{
                    display: 'block',
                    margin: '0 auto',
                    fontSize: '1.5em',
                    color: 'black',
                  }}
                />
                <h3 style={{ marginTop: '10px', textAlign: 'center' }}>
                  {app.name}
                </h3>
              </div>
            </Link>
          ))
        ) : (
          <div style={{ textAlign: 'center', marginTop: '80px' }}>
            {auth.isLoading ? (
              <div style={{ textAlign: 'center', marginTop: '80px' }}>
                <Spinner />
                <Text>Loading apps...</Text>
              </div>
            ) : (
              <Alert status='warning'>
                <AlertIcon />
                No apps assigned to you. Please contact IT to include apps
              </Alert>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
