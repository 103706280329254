import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

import useStore from './lib/store';
import Home from './pages/home';
import DCS from './pages/dcs';
import BG from './pages/bg';
import EBSResp from './pages/ebsresp';
import MFNC from './pages/mfnc';
import MFOrderStatus from './pages/mf-order-status';
import PLMReport from './pages/plm-report';
import PEInspectionConsole from './pages/pe-inspection-console';
import InstrumentCalibration from './pages/instrument-calibration';
import PEInventoryReport from './pages/pe-inventory-report';

import Header from './components/layout/header';
import './App.css';
import { useEffect } from 'react';

function App() {
  const auth = useAuth();
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  useEffect(() => {
    if (
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading      
    ) {
      console.log('redirecting to login');
      auth.signinRedirect();
    }
    if (!user) {
      setUser(auth.user);
    }
  }, [auth, setUser, user]);

  return (
    <>
      <Header user={user} />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/dcs' element={<DCS />} />
        <Route path='/rmncr' element={<MFNC />} />
        <Route path='/bg' element={<BG />} />
        <Route path='/ebs-resp' element={<EBSResp />} />
        <Route path='/mf-order-status' element={<MFOrderStatus />} />
        <Route path='/plm-report' element={<PLMReport />} />
        <Route path='/pe-inspection-console' element={<PEInspectionConsole />} />
        <Route path='/inst-calib' element={<InstrumentCalibration />} />
        <Route path='/pe-invent-report' element={<PEInventoryReport />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </>
  );
}

export default App;
